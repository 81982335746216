import applicationService from '@/services/applicationService';

function mergeNewArray(oldArr, newArr) {
  return newArr.map((currentTenant, index) => ({
    ...oldArr[index],
    ...currentTenant
  }));
}

export default {
  async fetchSubletApplication(ctx, landlordId) {
    const application =
      await applicationService.getSubletApplication(landlordId);

    if (application) {
      ctx.commit('setApplication', application);
      localStorage.setItem('applicationId', application.id);
      return application;
    }
    localStorage.removeItem('applicationId');
    return null;
  },
  async editCurrentTenants(ctx, { applicationId, currentTenants }) {
    await applicationService.updateCurrentTenants(
      applicationId,
      currentTenants
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      currentTenants: mergeNewArray(
        ctx.state.application.currentTenants,
        currentTenants
      )
    });
  },
  async editSecondaryTenants(ctx, { applicationId, secondaryTenants }) {
    await applicationService.updateSecondaryTenants(
      applicationId,
      secondaryTenants
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      secondaryTenants: mergeNewArray(
        ctx.state.application.secondaryTenants,
        secondaryTenants
      )
    });
  },
  async editApartment(ctx, { applicationId, apartment }) {
    await applicationService.updateApartment(applicationId, apartment);

    ctx.commit('setApplication', {
      ...ctx.state.application,
      apartment: {
        ...ctx.state.application.apartment,
        ...apartment
      }
    });
  },
  async editReason(ctx, { applicationId, reason }) {
    const { data } = await applicationService.updateReason(
      applicationId,
      reason
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      computedDocumentsGroups: data,
      reason: {
        ...ctx.state.application.reason,
        ...reason
      }
    });
  },
  async updateHasReadInfoCheck(ctx, applicationId) {
    await applicationService.updateHasReadInfo(applicationId);

    ctx.commit('setApplication', {
      ...ctx.state.application,
      hasReadInfo: true
    });
  },
  async submitApplication(ctx, applicationId) {
    await applicationService.updateApplication(applicationId);
    ctx.commit('setApplicationStatus', 'TREATING');
  },
  async addDocument(ctx, { applicationId, file, category }) {
    const doc = await applicationService.postFile(
      applicationId,
      file,
      category
    );

    ctx.commit('addDoc', doc);
  },
  async removeDocument(ctx, { applicationId, uuid }) {
    await applicationService.deleteFile(applicationId, uuid);
    ctx.commit('removeDoc', uuid);
  }
};
